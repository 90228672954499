<template>
  <b-modal
      :id="modalId"
      title="Edit period"
      centered
      title-class="font-22"
      ref="modal"
      :modalClass="'modal-timesheet'"
      @hidden="hiddenModal"
  >
    <form>
      <div class="d-flex justify-content-between">
        <div class="form-group required w-50 mr-2">
          <label for="employee">Employee</label>
          <multiselect
              v-model="form.employee"
              id="employee"
              track-by="uid"
              label="name"
              :searchable="true"
              :options="employees"
              :class="{'is-invalid': [$v.form.employee.$anyError].some(e => e)}"
              placeholder="Choose Employee"
          />
        </div>
        <div class="form-group required w-50">
          <label for="dayType">Day Type</label>
          <multiselect
              v-model="form.dayType"
              id="dayType"
              track-by="value"
              label="text"
              :searchable="true"
              :options="dayTypes"
              :class="{'is-invalid': [$v.form.dayType.$anyError].some(e => e)}"
              placeholder="Choose Day Type"
          />
        </div>
      </div>
      <div class="form-group">
        <date-picker
            v-model="form.dateRange"
            :disabled-date="disabledDate"
            range
            :format="'DD-MM-YYYY'"
        >
          <template #input>
            <div class="d-flex justify-content-between">
              <div class="form-group required w-50 mr-2">
                <label>Start Date</label>
                <input
                    class="form-control mr-2"
                    :class="{ 'is-invalid':  [$v.form.dateRange.$anyError].some(e => e)}"
                    placeholder="Choose Start Date"
                    :value="form.dateRange[0] ? $moment(form.dateRange[0]).format('DD.MM.YYYY') : ''"
                >
              </div>
              <div class="form-group required w-50">
                <label>End Date</label>
                <input
                    class="form-control"
                    :class="{ 'is-invalid':  [$v.form.dateRange.$anyError].some(e => e)}"
                    placeholder="Choose End Date"
                    :value="form.dateRange[1] ? $moment(form.dateRange[1]).format('DD.MM.YYYY') : ''"
                >
              </div>
            </div>
          </template>
          <template #icon-calendar>
            <svg v-if="[$v.form.dateRange.$anyError].some(e => e)"></svg>
          </template>
        </date-picker>
      </div>
    </form>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" disabled v-if="loading">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </b-button>
        <button class="btn btn-primary" type="submit" @click.prevent="handleSubmit" v-else>Save</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {showErrorToast} from "@/utils/toasts";

export default {
  name: 'EditPeriodModal',
  props: {
    modalId: {
      type: String
    },
    employees: {
      type: Array,
      default: () => []
    },
    dayTypes: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        employee: null,
        dayType: null,
        dateRange: [
            this.$moment().toDate(),
            this.$moment().toDate()
        ]
      }
    }
  },
  validations: {
    form: {
      employee: { required },
      dayType: { required },
      dateRange: { required: (model) => model.some(el => el) }
    }
  },
  methods: {
    handleSubmit() {
      if (this.$v.form.$invalid) {
        showErrorToast('Please, fill the form with valid data')
        return this.$v.form.$touch()
      }
      this.$emit('onSubmit', {
        date_start: this.$moment(this.form.dateRange[0]).format('YYYY-MM-DD'),
        date_end: this.$moment(this.form.dateRange[1]).format('YYYY-MM-DD'),
        type_id: this.form.dayType.value,
        employee_uid: this.form.employee.uid
      })
    },
    hiddenModal() {
      this.form.employee = null
      this.form.dayType = null
      this.form.dateRange = [
        this.$moment().toDate(),
        this.$moment().toDate()
      ]
      this.$v.form.$reset()
    },
    disabledDate(date) {
      return date < this.$moment().add(-1, 'days').toDate()
    }
  },
  watch: {
    'form.startDate'(val) {
      if (!val) {
        this.form.endDate = null
      }
    }
  }
}
</script>

