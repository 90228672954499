<template>
  <b-modal
      :id="modalId"
      :title="`${attendance ? attendance.name : ''} - ${attendance?.data?.active_date ? $moment(attendance.data.active_date, 'YYYY-MM-DD').format('DD MMM, YYYY') : ''}`"
      centered
      title-class="font-22"
      hide-footer
      ref="modal"
      @hide="handleHidenModal"
  >
    <b-form @submit="handleSubmit">
      <b-form-group
          label="Day type"
          label-for="Day type"
          label-cols="3"
          content-cols="9"
      >
        <b-form-select
            id="type"
            v-model="form.type_id"
            :class="{ 'is-invalid': (!$v.form.type_id.required && $v.form.type_id.$dirty) }"
            :options="dayTypes"
        />
      </b-form-group>
      <b-form-group
          label="Vacation hours spent type"
          label-for="Vacation hours spent"
          label-cols="3"
          content-cols="9"
      >
        <b-form-select
            id="type"
            v-model="form.vacation_hour_spent_type"
            :options="[
            { text: 'Automatic', value: 1 },
            { text: 'Manual', value: 0 },
          ]"
        />
      </b-form-group>
      <b-form-group
          label="Vacation hours spent"
          label-for="Vacation hours spent"
          label-cols="3"
          content-cols="9"
      >
        <input type="text" class="form-control mr-1" placeholder="Amount hours" :disabled="(form.available_hours <= 0 && form.type_id !== 1 && form .type_id !== 2 && this.form.type_id !== null || form.vacation_hour_spent_type === 1)" @input="changeHoursSpent($event)" v-model="form.vacation_hour_spent">
        <p class="pt-2">Vacation hours available: {{ form.available_hours }} hours</p>
      </b-form-group>
      <b-form-group
          label="Penalty"
          label-for="Penalty"
          label-cols="3"
          content-cols="9"
      >
        <input type="number" class="form-control mr-1" placeholder="Penalty count" :disabled="form.vacation_hour_spent_type === 1" v-model="form.penalty" @input="() => form.vacation_hour_spent_type = 0">
      </b-form-group>
      <b-form-group
          label="Note"
          label-for="note"
          label-cols="3"
          content-cols="9"
      >
        <b-form-textarea
            placeholder="Write note..."
            id="note"
            v-model="form.note"
        />
      </b-form-group>
      <b-form-group
          label="Overtime"
          label-for="Overtime"
          label-cols="3"
          content-cols="9"
      >
        <div class="d-flex" style="max-height: 36px">
          <input type="text" v-b-hover="handleHover" v-model="form[`${form.overtime_type === 1 ? 'overtime_hours' : 'overtime_money'}`]" class="form-control mr-1" :placeholder="form.overtime_type === 1 ? 'Overtime hours' : 'Overtime $'">
          <div v-if="form.overtimeHover" class="icon-hover">
            <span v-if="form.overtime_type === 1">hours</span>
            <span v-if="form.overtime_type === 2">$</span>
          </div>
          <b-button-group>
            <b-button :class="{'active': form.overtime_type === 1}" @click="setOvertimeType(1)">Hours</b-button>
            <b-button :class="{'active': form.overtime_type === 2}" @click="setOvertimeType(2)">Money</b-button>
          </b-button-group>
        </div>
      </b-form-group>
      <b-form-group
          label="Location"
          label-for="location"
          label-cols="3"
          content-cols="9"
      >
        <b-form-select
            id="location"
            v-model="form.location_id"
            :class="{ 'is-invalid': (!$v.form.location_id.required && $v.form.location_id.$dirty) }"
            :options="locations"
        />
      </b-form-group>
      <div class="w-100" v-if="form.form_type === 2">
        <b-form-group
            label="Work Time start at"
            label-for="day-start"
            label-cols="3"
            content-cols="9"
        >
          <date-picker
              id="day-start"
              v-model="form.check.checked_at"
              type="time"
              value-type="format"
              format="HH:mm"
          />
        </b-form-group>
        <div class="" v-if="form.check.break.length > 0" style="margin-bottom: 1rem;">
          <div class="form-row form-group" v-for="(item, idx) in $v.form.check.break.$each.$iter" :key="idx">
            <label class="col-3 col-form-label" for="">Break {{ parseInt(idx) + 1 }}</label>
            <div class="col-9">
              <div class="d-flex align-items-center">
                <div class="row">
                  <div class="col">
                    <date-picker
                        id="day-end"
                        v-model="item.$model['date_start']"
                        type="time"
                        value-type="format"
                        format="HH:mm"
                    />
                  </div>
                  <div class="col mr-2">
                    <date-picker
                        id="day-end"
                        v-model="item.$model['date_end']"
                        type="time"
                        value-type="format"
                        format="HH:mm"
                    />
                  </div>
                </div>
                <div style="cursor: pointer; width: 20px; display: flex; align-items: center; justify-content: center" @click.prevent="() => form.check.break.splice(idx, 1)">x</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <div class="d-flex justify-content-center">
              <p class="text-secondary mr-2"> Not added break </p>
              <i style="cursor: pointer;" @click.prevent="addBreak">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
              </i>
            </div>
          </div>
        </div>
        <div class="form-group" v-if="form.check.break.length > 0 && form.check.break.length < 2">
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click.prevent="addBreak">Add +</button>
          </div>
        </div>
        <b-form-group
            label="Work Time end at"
            label-for="day-end"
            label-cols="3"
            content-cols="9"
        >
          <date-picker
              id="day-end"
              v-model="form.check.date_end"
              type="time"
              value-type="format"
              format="HH:mm"
          />
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end" v-if="isHasAccess">
        <b-button
            @click="handleRemove"
            variant="danger"
            v-if="form.form_type === 2"
        >
          Remove
        </b-button>
        <b-button
            type="submit"
            variant="primary"
            class="ml-2"
        >
          {{ form.form_type === 1 ? 'Save' : 'Update' }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import {required} from "vuelidate/lib/validators";

export default {
  name: 'ModalForm',
  props: ['modalId', 'isHasAccess'],
  components: {
    datePicker
  },
  data() {
    return {
      moment,
      form: {
        form_type: null, // 1 - store; 2-update; 3-delete
        type_id: null,
        vacation_hour_spent: null,
        vacation_hour_spent_type: null,
        available_hours: 0,
        note: null,
        overtime_hours: null,
        overtime_money: null,
        overtime_type: 1,
        overtimeHover:false,
        location_id: null,
        penalty: 0,
        typeDay: null,
        check: {
          checked_at: null,
          date_end: null,
          break: []
        }
      }
    }
  },
  validations: {
    form: {
      type_id: { required },
      location_id: { required },
      check: {
        break: {
          $each: {
            date_start: { required },
            date_end: { required }
          }
        }
      }
    }
  },
  computed: {
    dayTypes() {
      return this.$store.state.attendances.dictionary.day_types.map(item => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    locations() {
      return this.$store.state.attendances.dictionary.locations.filter(item => (item.title === 'Remote' || item.title === 'Office Tashkent')).map(item => {
        return {
          text: item.title,
          value: item.id
        }
      })
    },
    attendance () {
      return this.$store.state.attendances.selectedDay
    }
  },
  watch: {
    'form.type_id' (val) {
      if (val) {
        if (this.dayTypes.find(item => item.value === val).text === 'Day-off' && this.form.form_type !==2) {
          this.form.vacation_hour_spent_type = 1
          this.form.vacation_hour_spent = 0
          this.form.available_hours = this.attendance?.data?.available_hours - this.form.vacation_hour_spent
        }
        if (this.dayTypes.find(item => item.value === val).text === 'Workday' && this.form.form_type !==2) {
          this.form.vacation_hour_spent = 0
          this.form.vacation_hour_spent_type = 0
          this.form.penalty = 0
          this.form.available_hours = this.attendance?.data?.available_hours - this.form.vacation_hour_spent
        }
        if (this.dayTypes.find(item => item.value === val).text === 'Non-working day' && this.form.form_type !==2) {
          this.form.vacation_hour_spent = 0
          this.form.penalty = 0
          this.form.vacation_hour_spent_type = 0
          this.form.available_hours = this.attendance?.data?.available_hours - this.form.vacation_hour_spent
        }
      }
    },
    'form.vacation_hour_spent_type' (type) {
      let res;
      const availableHours = this.attendance?.data?.available_hours ?? 0;
      if (type && type === 1 && this.form.form_type !== 2) {
        res = availableHours - 7;
        if (res >= 0) {
          this.form.vacation_hour_spent = 7
          this.form.available_hours = availableHours - this.form.vacation_hour_spent
        } else {
          this.form.penalty = -res
          this.form.vacation_hour_spent = availableHours % 7
          this.form.available_hours = availableHours - (availableHours % 7)
        }
      }
    },
    'attendance' (obj) {
      if (obj?.data && obj.data?.vacation_hour_spent >= 0) {
        this.$nextTick(() => {
          this.form.form_type = 2
          this.form.type_id = obj.data.type_id
          this.form.vacation_hour_spent_type = obj.data?.is_automatic ? 1 : 0
          this.form.note = obj.data.note
          this.form.penalty = obj.data.penalty
          this.form.overtime_hours = obj.data.overtime_hours
          this.form.overtime_money = obj.data.overtime_money
          this.form.overtime_type = obj.data.overtime_hours > 0 ? 1 : 2
          this.form.location_id = obj.data.location_id
          this.form.check.checked_at = obj.data.checked_at ? this.$moment(obj.data.checked_at, 'YYYY-MM-DD HH:mm').format('HH:mm') : null
          this.form.check.date_end = obj.data.date_end ? this.$moment(obj.data.date_end, 'YYYY-MM-DD HH:mm').format('HH:mm') : null
          this.form.vacation_hour_spent = obj.data.vacation_hour_spent
          this.form.available_hours = this.attendance?.data?.available_hours
          if (obj?.data.attendance_break.length > 0) {
            obj?.data.attendance_break.forEach(item => {
              this.form.check.break.push({
                date_start: this.$moment(item.date_start, 'YYYY-MM-DD HH:mm').format('HH:mm'),
                date_end: this.$moment(item.date_end, 'YYYY-MM-DD HH:mm').format('HH:mm')
              })
            })
          }
        })
      } else {
        this.$nextTick(() => {
          this.form.form_type = 1
          this.form.location_id = this.attendance?.location_id ? this.attendance.location_id : null
          this.form.available_hours = this.attendance?.data?.available_hours
          this.form.vacation_hour_spent_type = 0
          this.form.typeDay = obj?.data.type
          switch (this.form.typeDay) {
            case 'weekend':
              this.form.type_id = this.dayTypes.find(day => day.text === 'Non-working day').value
              return
            case 'workday':
              this.form.type_id = this.dayTypes.find(day => day.text === 'Workday').value
              return
          }
        })
      }
    },
  },
  methods:  {
    handleHover(hovered) {
      this.form.overtimeHover = hovered
    },
    addBreak () {
      this.form.check.break.push({
        date_start: null,
        date_end: null
      })
    },
    setOvertimeType(type) {
      switch (type) {
        case 1:
          this.form.overtime_type = 1
          this.form.overtime_money = 0
          break
        case 2:
          this.form.overtime_type = 2
          this.form.overtime_hours = 0
          break
      }
    },
    handleHidenModal() {
      this.$emit('onClose')
      this.$store.commit('attendances/SET_SELECTED_DAY', null)
      this.$v.form.$reset()
      for (const [key] of Object.entries(this.form)) {
        if (key !== 'form_type' && key !== 'overtime_type' && key !== 'check') {
          this.form[key] = null
        }
      }

      this.form.check.break = []
    },
    deleteNullKey(obj) {
      const data = obj
      Object.keys(data).filter(key => {
        if (data[key] === null || data[key] === []) {
          delete data[key]
        }
      })

      return data
    },
    handleSubmit(event) {
      event.preventDefault();
      if (this.$v.form.$invalid) {
        this.$v.form.$touch()
      } else {
        let data = {
          date: this.$moment(this.attendance.data.active_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          employee_uid: this.attendance.uid,
          note: this.form.note,
          penalty: this.form.penalty,
          vacation_hour_spent: this.form.vacation_hour_spent,
          overtime_hours: this.form.overtime_hours,
          overtime_money: this.form.overtime_money,
          type_id: this.form.type_id,
          location_id: this.form.location_id,
          is_automatic: this.form.vacation_hour_spent_type
        }
        if (this.form.form_type === 2) {
          const getBreaks = () => {
            let obj = {}
            this.form.check.break.forEach((item, idx) => {
              obj[`break_start[${idx}]`] = this.$moment(this.attendance.data.date, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' ' + item.date_start
              obj[`break_end[${idx}]`] = this.$moment(this.attendance.data.date, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' ' + item.date_end
            })

            return obj
          }
          data = {
            ...data,
            ...getBreaks(),
            checked_at: (this.form.check.checked_at && this.attendance.data.date) ? String(this.$moment(this.attendance.data.date, 'YYYY-MM-DD').format('YYYY-MM-DD')) + ' ' + this.form.check.checked_at : null,
            date_end: (this.form.check.date_end && this.attendance.data.date_end) ? String(this.$moment(this.attendance.data.date_end, 'YYYY-MM-DD').format('YYYY-MM-DD')) + ' ' + this.form.check.date_end : this.form.check.date_end ? this.$moment(this.attendance.data.date, 'YYYY-MM-DD').format('YYYY-MM-DD') + ' ' + this.form.check.date_end : null
          }
        }

        this.$emit('onSubmit', {data: this.deleteNullKey(data), id: this.attendance?.data.id}, this.form.form_type === 1 ? 'create' : 'update')
      }
    },
    handleRemove(event){
      event.preventDefault();
      this.$bvModal.msgBoxConfirm('Delete this item?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$emit('onSubmit', {id: this.attendance?.data.id}, 'delete')
            }
          });
    },
    changeHoursSpent(event) {
      let res = this.attendance?.data?.available_hours - event.target.value
      if (res >= 0) {
        this.form.vacation_hour_spent = event.target.value
        this.form.available_hours = res
      } else {
        this.form.vacation_hour_spent = 0
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.icon-hover {
  position: absolute;
  right: 42%;
  bottom: 7px;
}
</style>
