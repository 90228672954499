<template>
  <div class="w-100">
    <div class="row">
      <div class="col-xl-3 col-lg-12">
        <h2 class="title-1">Timesheet</h2>
      </div>
      <div class="col-xl-9 col-lg-12">
        <div class="timesheet__filters__container">
          <div class="timesheet__filters__item">
            <div class="timesheet__filters__select mr-2">
              <img src="@/assets/images/calendar/select-angle.png" alt="" />
              <select
                  v-model="type"
                  @change="handleFilterChange"
              >
                <option v-for="item in locations" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="timesheet__filters__select">
              <img src="@/assets/images/calendar/select-angle.png" alt="" />
              <select
                  id="department"
                  v-model="department"
                  @change="handleFilterChange"
              >
                <option :value="null">All departments</option>
                <option
                    v-for="department in departments"
                    :key="department.value"
                    :value="department.value">
                  {{ department.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="timesheet__filters__item">
            <div class="timesheet__filters__select">
              <img src="@/assets/images/icons/ic-calendar-today.svg" alt="">
              <select
                  id="period"
                  v-model="month"
                  @change="handleFilterChange"
              >
                <option
                    v-for="month in months"
                    :key="month.value"
                    :value="month.value">
                  {{ month.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="timesheet__filters__item">
            <button class="btn btn-primary mr-2" @click.prevent="$emit('openEditPeriodModal')" v-if="$hasAccess(['admin', 'hrm'])">
              <i class="mr-1">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3335 2.00002H11.6668V0.666687H10.3335V2.00002H3.66683V0.666687H2.3335V2.00002H1.66683C0.933496 2.00002 0.333496 2.60002 0.333496 3.33335V14C0.333496 14.7334 0.933496 15.3334 1.66683 15.3334H12.3335C13.0668 15.3334 13.6668 14.7334 13.6668 14V3.33335C13.6668 2.60002 13.0668 2.00002 12.3335 2.00002ZM12.3335 14H1.66683V5.33335H12.3335V14ZM3 7H5V9H3V7ZM5 10H3V12H5V10ZM6 7H8V9H6V7ZM8 10H6V12H8V10ZM9 7H11V9H9V7ZM11 10H9V12H11V10Z" fill="white"/>
                </svg>
              </i>
              Edit period
            </button>
            <b-dropdown
                variant="primary"
                dropright
            >
              <template slot="button-content">
                <i class="fas fa-download"></i> CSV
              </template>
              <b-dropdown-item
                  v-for="year in years"
                  :key="year"
                  @click="handleDownloadReport(year)"
              >
                {{ year }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TimesheetFilter",
  props: ['period'],
  data () {
    return {
      month: null,
      type: null,
      department: null,
      years: [],
    }
  },
  methods: {
    async handleFilterChange () {
      this.$emit('update', {
        month: this.month,
        type: this.type,
        department: this.department
      })
    },
    constructYearDictionary () {
      for (let i = 2020; i <= new Date().getFullYear(); i += 1) {
        this.years.push(i);
      }
    },
    updateFilterData () {
      if (this.filterParams?.search_employee_name
          || this.filterParams?.date
          || this.filterParams?.department
          || this.filterParams?.location_id) {
        this.type = this.filterParams?.location_id ?? null
        this.department = this.filterParams?.department_id ?? null
        this.month = this.filterParams?.date ? this.filterParams?.date : this.months[0].value
      }
    },
    handleDownloadReport(year) {
      this.$emit('updateLoad', true)
      this.$store.dispatch('attendances/downloadReport', year).then(() => {
        this.$emit('updateLoad', false)
      });
    },
  },
  computed: {
    locations() {
      const data = this.$store.state.attendances.dictionary.locations
          .filter(item => (item.title === 'Remote' || item.title === 'Office Tashkent'))
          .map(item => {
        return {
          text: item.title,
          value: item.id
        }
      })

      data.unshift({
        text: 'All locations',
        value: null
      })

      return data
    },
    months() {
      if (this.$store.state.attendances.months) {
        const data = this.$store.state.attendances.months.map(item => {
          return {
            text: this.$moment(item).format('MMMM, YYYY'),
            value: item
          }
        })

        data.unshift({
          text: this.$moment(this.$store.state.attendances.months.length > 0
              ? this.$store.state.attendances.months[0]
              : this.$moment()).add(1, 'month').format('MMMM, YYYY'),
          value: this.$moment(this.$store.state.attendances.months.length > 0
              ? this.$store.state.attendances.months[0]
              : this.$moment()).add(1, 'month').format('YYYY-MM-DD')
        })

        return data
      }

      return []
    },
    departments() {
      return this.$store.state.departments.list.map((department) => ({
        text: department.title, value: department.id
      })).sort((a, b) => a.text.localeCompare(b.text));
    },
    filterParams () {
      return this.$store.state.profile.filterParams
    }
  },
  async mounted() {
    await this.$store.dispatch('departments/getDepartments');
    await this.$store.dispatch('attendances/getLocations');
    this.$nextTick(() => {
      this.updateFilterData()
      this.constructYearDictionary()
    })
  }
}
</script>

<style lang="scss" scoped>
.timesheet {
  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    justify-content: space-between;
    h2 {
      font-size: 16px;
      color: #2a3042;
      font-weight: bold;
      margin: 0;
      text-transform: uppercase;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      label {
        font-size: 14.4px;
        color: #2a3042;
        font-weight: bold;
        margin: 0px 8px 0px 0px;
      }
      label[for="department"] {
        margin: 0px 10px 0px 0px;
      }
    }


    &__select {
      width: auto;
      min-width: 150px;
      max-width: 160px;
      position: relative;
      img {
        top: 50%;
        right: 12px;
        position: absolute;
        pointer-events: none;
        transform: translateY(-50%);
      }
      select {
        height: 37px;
        outline: none;
        width: 100%;
        color: #343A40;
        padding: 8px 10px;
        font-size: 13px;
        appearance: none;
        border: 1px solid #E3E4E8;
        cursor: pointer;
        border-radius: 4px;
      }
    }
    &__input {
      width: 218px;
      position: relative;
      img {
        top: 50%;
        right: 9px;
        position: absolute;
        pointer-events: none;
        transform: translateY(-50%);
      }
      input {
        height: 37px;
        outline: none;
        width: 100%;
        color: #555555;
        padding: 0 11px;
        font-size: 14.4px;
        border: 2px solid #e4e6ec;

        &::-webkit-input-placeholder {
          color: #555555;
        }
        &::-moz-placeholder {
          color: #555555;
        }
        &:-ms-input-placeholder {
          color: #555555;
        }
        &:-moz-placeholder {
          color: #555555;
        }
      }
    }
  }
}
.timesheet__filters__input{
  width: 573px;
}
.selectable-filters{
  display: flex;
  /*padding: 10px 0;
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;*/
}

@media screen and (max-width: 1199px) {
  .timesheet {
    &__filters {
      &__item {
        padding-bottom: 10px;
      }
      &__container {
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .timesheet {
    &__filters {
      &__container {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .timesheet {
    &__filters {
      &__item {
        width: 100%;
        margin-right: 0;
        &:nth-child(2) {
          .timesheet__filters__select {
            max-width: 100%;
            min-width: 100%;
          }
        }
        &:last-child {
          margin-right: auto;
        }
      }
      &__select {
        max-width: 49%;
        min-width: 49%;
      }
      &__container {
        flex-direction: column;
        &:last-child {
          button {
            width: 50%;
          }
          .b-dropdown {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
