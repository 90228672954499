<template>
  <b-modal
    :id="modalId"
    :title="`Select a date type for ${moment.utc(date, 'YYYY-MM-DD').local().format('DD MMM, YYYY')}`"
    centered
    title-class="font-22"
    hide-footer
    @hide="handleHidenModal"
  >
    <b-form @submit="handleSubmit">
      <b-form-group>
        <b-form-radio-group
          v-model="type"
          :options="options"
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ModalForm',
  props: ['modalId', 'date', 'initialType'],
  data() {
    return {
      moment,
      type: 'workday',
      options: [
        { text: 'Workday', value: 'workday' },
        { text: 'Holiday', value: 'holiday' }
      ]
    }
  },
  watch: {
    initialType(value) {
      this.type = value ? value : 'workday';
    }
  },
  methods: {
    handleHidenModal() {
      this.$emit('onClose');
    },
    async handleSubmit(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.type);
    }
  }
}
</script>
