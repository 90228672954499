<template>
  <layout>
    <DayTypeModal
        :modalId="dayTypeModalId"
        :date="currentDay"
        :initialType="currentDayType"
        @onClose="handleDayTypeModalClose"
        @onSubmit="handleDayTypeModalSubmit"
    />
    <DaySettingsModal
        :modalId="daySettingsModalId"
        :day="currentDay"
        :isHasAccess="$hasAccess(['admin', 'hrm'])"
        @onClose="handleSettingsModalClose"
        @onSubmit="handleSettingsModalSubmit"
    />
    <EditPeriodModal
        :modalId="editPeriodModalId"
        :employees="employeesList"
        :dayTypes="dayTypes"
        :loading="loadModal"
        @onSubmit="handleEditPeriod"
    />
    <div class="timesheet" v-load="loadPage">
      <TimesheetFilter
          :period="month"
          @openEditPeriodModal="handleEditPeriodClick"
          @update="updateFilter"
      />
      <div class="calendar" ref="calendar-body">
        <div class="calendar__header">
          <div class="calendar__header__item">
            <input type="text" class="form-control" placeholder="Employees" v-model="search">
          </div>
          <div class="calendar__header__item">
            <i v-b-tooltip.hover.top="{ title: 'Penalties', boundary: 'viewport' }">
              <img src="@/assets/images/icons/ic-monetization_on.svg" alt="">
            </i>
          </div>
          <div class="calendar__header__item">
            <i
                class="calendar__header__icon"
                :class="isVacationTypeSwitchChecked ? 'left-icon' : 'spent-icon'"
                v-b-tooltip.hover.top="{ title: `Click to see ${isVacationTypeSwitchChecked ? 'spent' : 'left'} vacation hours`, boundary: 'viewport' }"
                @click.prevent="handleChangeVacationHoursType"
            ></i>
          </div>
          <div class="calendar__header__item calendar__header__item_hidden" ref="days-header">
            <div
                v-for="day in max_day"
                :key="day"
                class="calendar__header__item-day"
                @click="handleHeaderDayClick(day)"
            >
              {{ day }}
            </div>
          </div>
        </div>
        <EmptyPage v-if="!employeesList.length"/>
        <div class="calendar__body">
          <div class="calendar__employees">
            <div
                v-for="(employee, index) in employeesList"
                :key="employee.uid"
                class="calendar__employees__item calendar__cell-text"
                :class="{'calendar__cell-text_probation': new Date() < new Date(employee.probation_period_end_date)}"
            >
              {{index + 1}}. {{ employee.name }}
              <i :class="employee.remotely ? 'remote-work-icon' : 'office-work-icon'"></i>
              <img
                  v-if="getMaternityStatus(employee)"
                  :src="icons.subtractIcon"
                  class="ml-1"
              >
            </div>
          </div>
          <div class="calendar__small-cell">
            <div
                v-for="employee in employeesList"
                :key="employee.uid"
                :class="[
                'calendar__small-cell__item calendar__cell-text',
                { 'calendar__cell-text_red': getTotalPenalties(employee.items) > 0 }
              ]"
            >
              {{ Math.ceil(employee.penalty_percent) }}%
            </div>
          </div>
          <div class="calendar__small-cell">
            <div
                v-for="employee in employeesList"
                :key="employee.uid"
                class="calendar__small-cell__item calendar__cell-text switch-cell"
            >
              <span>{{ getVacationHours(employee.vacation?.total_value, employee.vacation?.available_value) }}</span>
            </div>
          </div>
          <div class="calendar__days" ref="days-body" @mouseleave="handleDayMouseLeave">
            <div
                v-for="(employee) in employeesList"
                :key="employee.uid"
                class="calendar__days__row"
            >
              <div
                  v-for="(item, j) in getCurrentDays(employee)"
                  :key="item.id"
                  class="calendar__days__item"
              >
                <div
                    class="calendar__days__item__content"
                    :class="getBackgroundClass(item.status)"
                    @click="handleDayClick({name: employee.name, uid: employee.uid, location_id: employee.location_id}, {
                    ...item.data,
                    type: item.type,
                    active_date: `${$moment(month, 'YYYY-MM-DD').format('YYYY-MM')}-${j + 1}`,
                    available_hours: employee?.vacation ? employee?.vacation.available_value : 0
                  })"
                    @mouseover="handleDayHover($event,
                    {
                    date: `${$moment(month, 'YYYY-MM-DD').format('YYYY-MM')}-${(parseInt(j) + 1)}`,
                    name: employee.name,
                    data: item.data
                    }
                  )"
                >
                  <div v-if="item.type === 'holiday'" class="timesheet-icon">
                    <i class="timesheet-icon__item holiday-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M11 19.91 10 22h4l-1-2.09c4-.65 7-5.28 7-9.91a8 8 0 0 0-16 0c0 4.63 3.08 9.26 7 9.91zm1-15.66v1.5A4.26 4.26 0 0 0 7.75 10h-1.5A5.76 5.76 0 0 1 12 4.25z"></path></svg>
                    </i>
                  </div>
                  <div v-if="item.type === 'overtime-workday'" class="timesheet-icon small">
                    <i v-if="item.data?.overtime_money > 0" class="timesheet-icon__item">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M21 4H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 11a3 3 0 0 0-3 3H7a3 3 0 0 0-3-3V9a3 3 0 0 0 3-3h10a3 3 0 0 0 3 3v6z"></path><path d="M12 8c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path></svg>
                    </i>
                    <i v-if="item.data?.overtime_hours > 0" class="timesheet-icon__item">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M5 2H4v2h1v1a7.014 7.014 0 0 0 3.433 6.02c.355.21.567.547.567.901v.158c0 .354-.212.691-.566.9A7.016 7.016 0 0 0 5 19v1H4v2h16v-2h-1v-1a7.016 7.016 0 0 0-3.434-6.021c-.354-.208-.566-.545-.566-.9v-.158c0-.354.212-.69.566-.9A7.016 7.016 0 0 0 19 5V4h1V2H5zm12 3a5.01 5.01 0 0 1-2.45 4.299A3.107 3.107 0 0 0 13.166 11h-2.332a3.114 3.114 0 0 0-1.385-1.702A5.008 5.008 0 0 1 7 5V4h10v1z"></path></svg>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="timesheet__all-count m-0 mt-2 font-weight-bold">Total: {{ employeesList.length }}</p>
      <div class="timesheet__statuses desktop-statuses">
        <div class="timesheet__statuses__item timesheet__statuses__item_grey">workday</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_dark-grey">non-working day</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_green">no delays</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_yellow">hours spent less 8</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_red">absent all day</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_maternity">maternity leave</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_day-off">day-off</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_holiday">holiday</div>
      </div>
      <div class="timesheet__statuses mobile-statuses">
        <div class="timesheet__statuses__item timesheet__statuses__item_grey">workday</div>
        <div class="timesheet__statuses__item timesheet__statuses__item_dark-grey">non-working day</div>
        <div class="timesheet__statuses__item_line">|</div>
        <b-dropdown
            id="dropdown-1"
            text="More"
            variant="transparent"
            class="status-dropdown"
        >
          <template #button-content>
            More
            <i class="pr-0">
              <svg
                  width="6"
                  height="7"
                  viewBox="0 0 8 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.666626 3.33333L3.99996 0L7.33329 3.33333H0.666626Z" fill="#323232"/>
              </svg>
            </i>
          </template>
          <b-dropdown-item>
            <div class="timesheet__statuses__item timesheet__statuses__item_green">no delays</div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="timesheet__statuses__item timesheet__statuses__item_yellow">hours spent less 8</div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="timesheet__statuses__item timesheet__statuses__item_red">absent all day</div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="timesheet__statuses__item timesheet__statuses__item_maternity">maternity leave</div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="timesheet__statuses__item timesheet__statuses__item_day-off">day-off</div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="timesheet__statuses__item timesheet__statuses__item_holiday">holiday</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div
          class="calendar__popup"
          :style="popupStyle"
          ref="popup"
      >
      </div>
    </div>
  </layout>
</template>

<script>
import DayTypeModal from './day-type-modal.vue';
import DaySettingsModal from './day-settings-modal.vue';
import TimesheetFilter from "../../../components/time-sheet/TimesheetFilter";
import EditPeriodModal from "@/components/time-sheet/EditPeriodModal.vue";
import {FREELANCER_ID, VACATION_HOURS_TYPE} from '@/utils/constants';

export default {
  name: 'TimeSheet',
  components: {
    DayTypeModal,
    DaySettingsModal,
    TimesheetFilter,
    EditPeriodModal,
  },
  data() {
    return {
      currentDay: null,
      currentDayType: null,
      currentEmployee: null,
      choosedEmployee: null,
      dayTypeModalId: 'day-type-modal',
      daySettingsModalId: 'day-settings-modal',
      editPeriodModalId: 'edit-period-modal',
      handleScrollDays: undefined,
      day: null,
      popupStyle: {
        top: '0px',
        left: '0px'
      },
      month: null,
      max_day: 0,
      scrollPosition: 0,
      loadPage: false,
      loadModal: false,
      search: '',
      icons: {
        subtractIcon: require('@/assets/images/icons/ic-subtract.svg')
      }
    }
  },
  async mounted() {
    this.loadPage = true
    await this.fetchRequests().finally(() => {
      this.month = this.filterParams?.date ? this.filterParams.date : this.months[1].value
      this.search = this.filterParams?.search_employee_name ?? ''
      this.$refs['calendar-body'].addEventListener('scroll', this.setMyScrollLocation)
      this.loadPage = false
    })
  },
  methods: {
    setMyScrollLocation(el) {
      this.scrollPosition = el.target.scrollTop
    },
    getMyScrollLocation(pos) {
      this.$refs['calendar-body'].scrollTo(0, pos)
    },
    setMonthData(data) {
      this.month = data.month
      this.max_day = data.max_day ?? parseInt(this.$moment(this.months[1].value, 'YYYY-MM-DD').endOf('month').format('DD'))
    },
    getStatusForCheckedAt(attendance) {
      if (attendance.checked_at) {
        if (attendance.vacation_hour_spent + attendance.penalty === 0) {
          return 'success'
        }
        if (attendance.vacation_hour_spent + attendance.penalty > 0 && attendance.vacation_hour_spent + attendance.penalty < 8) {
          return 'warning'
        }
        if (attendance.vacation_hour_spent + attendance.penalty === 8) {
          return 'danger'
        }
      }
      if (!attendance.checked_at && this.$moment(attendance.date, 'YYYY-MM-DD') > this.$moment() && attendance.attendance_type.id === 3) {
        return 'day-off-future'
      }
      if (!attendance.checked_at && this.$moment(attendance.date, 'YYYY-MM-DD') > this.$moment() && attendance.attendance_type.id === 1) {
        return 'normal-future'
      }
      if (!attendance.checked_at && attendance?.attendance_type?.id === 3) {
        return 'day-off'
      }
      if (!attendance.checked_at && attendance?.attendance_type?.id === 5) {
        return 'maternity-leave'
      }
      if (!attendance.checked_at && attendance?.attendance_type?.id === 2) {
        return null
      }
      else {
        return 'danger'
      }
    },
    getStatusDateContract(schedule, contracts, cur_day) {
      let status = contracts.every(item => {
        if (schedule) {
          if ((item.start_date > cur_day && item.start_date !== 0) || (item.end_date < cur_day && item.end_date !== 0)) {
            return true
          }
        }
        if (!schedule) {
          if (item.start_date <= cur_day && item.end_date > cur_day || item.start_date <= cur_day && item.end_date === 0) {
            return true
          }
        }
      })

      return status
    },
    getContractDates(employee, date) {
      if (employee?.contracts && employee.contracts.length > 0) {
        return employee.contracts.map(item => {
          return {
            start_date: this.$moment(item.start_date, 'YYYY-MM-DD').format('MM') === this.$moment(this.$moment(date, 'YYYY-MM-DD')).format('MM') ? parseInt(this.$moment(item.start_date, 'YYYY-MM-DD').format('DD')) : 0,
            end_date: this.$moment(item.end_date, 'YYYY-MM-DD').format('MM') === this.$moment(this.$moment(date, 'YYYY-MM-DD')).format('MM') ? parseInt(this.$moment(item.end_date, 'YYYY-MM-DD').format('DD')) : 0,
          }
        })
      }

      return [
        {
          start_date: 0,
          end_date: 0
        }
      ]
    },
    getCurrentDays(employee) {
      if (this.employeesList.length > 0 && this.month) {
        let days = []
        for (let i = 0; i < this.max_day; i += 1) {
          let month = this.$moment(this.month, 'YYYY-MM-DD').format('YYYY-MM')
          let currentToday = `${month}-${i + 1 < 10
              ? `0${i + 1}`
              : i + 1}`
          let dayOfWeek = this.$moment(`${month}-${i+1}`, 'YYYY-MM-DD').format('dddd')
          let attends = employee.attendances.filter(item => this.$moment(item.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === currentToday)

          let datesContract = this.getContractDates(employee, this.month)
          if (employee.is_default_schedule) {
            if ((dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday' || this.getStatusDateContract(true, datesContract, i+1)) && attends.length === 0) {
              days.push({
                id: i,
                type: 'weekend',
                status: null,
              })
            } else {
              if (employee.category.id === FREELANCER_ID) {
                const type = attends.length > 0
                  ? (attends[0]?.overtime_hours > 0 || attends[0]?.overtime_money > 0) ? 'overtime-workday' : 'workday'
                  : null;
                days.push({
                  id: i,
                  type,
                  status: attends.length > 0 ? this.getStatusForCheckedAt(attends[0]) : null,
                  data: attends.length > 0 ? attends[0] : null
                })
              } else {
                days.push({
                  id: i,
                  type: (attends.length > 0 && attends[0]?.overtime_hours > 0 || attends[0]?.overtime_money > 0) ? 'overtime-workday' : 'workday',
                  status: attends.length > 0 ? this.getStatusForCheckedAt(attends[0]) : 'ordinary',
                  data: attends.length > 0 ? attends[0] : null
                })
              }
            }
          } else {
            let status = employee.employee_schedule.some(el => el === dayOfWeek)
            if ((employee.employee_schedule.length > 0 || attends[0]) && status && this.getStatusDateContract(false, datesContract, i+1) ) {
              days.push({
                id: i,
                type: (attends.length > 0 && attends[0]?.overtime_hours > 0 || attends[0]?.overtime_money > 0) ? 'overtime-workday' : 'workday',
                status: attends.length > 0 ? this.getStatusForCheckedAt(attends[0]) : 'workday',
                data: attends.length > 0 ? attends[0] : null
              })
            }
            else if ((employee.employee_schedule.length > 0 || attends[0]) && !status && this.getStatusDateContract(false, datesContract, i+1) && attends.length > 0) {
              days.push({
                id: i,
                type: (attends[0]?.overtime_hours > 0 || attends[0]?.overtime_money > 0) ? 'overtime-workday' : 'workday',
                status: this.getStatusForCheckedAt(attends[0]),
                data: attends[0]
              })
            }
            else {
              days.push({
                id: i,
                type: 'weekend',
                status: null
              })
            }
          }
        }
        this.holidays.forEach(item => {
          days.forEach(day => {
            if (parseInt(this.$moment(item.date, 'YYYY-MM-DD').format('DD')) === day.id + 1) {
              day.type = 'holiday'
              day.status = 'ordinary'
              day.data = null
            }
          })
        })

        return days
      }

      return []
    },
    updatePopup(data) {
      //  let findDay = this.dayTypes.find(value => value === data.data.type_id)
      const popup = this.$refs['popup']
      popup.innerHTML = ''
      const h3 = document.createElement('h3')
      const h4 = document.createElement('h4')
      const p1 = document.createElement('p')
      const p2 = document.createElement('p')
      const p3 = document.createElement('p')
      const p4 = document.createElement('p')
      const p5 = document.createElement('p')
      const p6 = document.createElement('p')
      let mock = '';
      if (data.data && data.data.date_interval.length > 0) {
        data.data.date_interval.forEach((item) => {
          if (data.data.date_interval.length === 1) {
            mock += `${this.$moment(item.date_start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}-${this.$moment(item.date_end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}`
          } else {
            mock += `<br />${this.$moment(item.date_start, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}-${this.$moment(item.date_end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}`
          }
        })
      }
      h3.textContent = this.$moment(data.date, 'YYYY-MM-DD').format('LL')
      h4.textContent = data.name
      p1.textContent = (data.data && data.data?.type_id) ? `Day type: ${this.dayTypes.find(item => item.value === data.data?.type_id).text}` : null
      p2.innerHTML = (data.data && data.data.checked_at && mock.length > 0) ? `Working time: ${mock}` : null
      p3.textContent = (data.data && data.data?.vacation_hour_spent > 0) ? `Vacation hours spent: ${data.data.vacation_hour_spent} hours` : null
      p4.textContent = (data.data && data.data?.penalty) ? `Penalty: ${Math.ceil(data.data?.penalty)} hours` : null
      p5.textContent = (data.data && (data.data?.overtime_hours > 0 || data.data?.overtime_money > 0)) ? `Overtime: ${data.data?.overtime_hours? data.data?.overtime_hours + ' hours' : data.data?.overtime_money + ' $'}` : null
      p6.textContent = (data.data?.note) ? `Note: ${data.data.note}` : null
      popup.appendChild(h3)
      popup.appendChild(h4)
      popup.appendChild(p1)
      popup.appendChild(p2)
      popup.appendChild(p3)
      popup.appendChild(p4)
      popup.appendChild(p5)
      popup.appendChild(p6)
    },
    handleDayHover(ev, data) {
      this.updatePopup(data)
      const bounds = ev?.srcElement.getBoundingClientRect()
      const popup = this.$refs['popup']
      popup.style.opacity = 1
      popup.style.top = `${bounds.top + 21}px`
      if (bounds.top + 140 >= window.innerHeight) {
        popup.style.top = `${bounds.top - 50}px`
      }
      if (bounds.left + 179 >= window.innerWidth) {
        popup.style.left = `${bounds.left - 169}px`;
      } else {
        popup.style.left = `${bounds.left + 22}px`;
      }
    },
    handleDayMouseLeave() {
      this.$refs['popup'].style.opacity = 0
    },
    async handleSettingsModalSubmit(data, type) {
      this.loadPage = !this.loadPage
      let scroll = this.scrollPosition
      switch (type) {
        case 'create':
          await this.$store.dispatch('attendances/storeAttendance', this.$convertToFormData({
            ...data.data,
            _method: 'POST'
          }))
          break;
        case 'update':
          await this.$store.dispatch('attendances/updateAttendance', {
            data: this.$convertToFormData({...data.data, _method: 'PATCH'}),
            id: data.id
          })
          break;
        case 'delete':
          await this.$store.dispatch('attendances/deleteAttendance', {
            data: this.$convertToFormData({_method: 'DELETE'}),
            id: data.id
          })
          break;
      }

      await this.$store.dispatch('attendances/getAttendancesBeta', this.filterParams).then(() => {
        this.loadPage = !this.loadPage
        this.getMyScrollLocation(scroll)
        this.$bvModal.hide(this.daySettingsModalId);
      });
      // await this.$store.dispatch('attendances/getAttendances', this.queryParams);
      // const fData = convertObjectToFormData({
      //   checked_at: data.time ? moment(this.currentDay.date + ' ' + data.time).utc().format('YYYY-MM-DD HH:mm:ss') : '',
      //   day_start: data.day_start ? moment(this.currentDay.date + ' ' + data.day_start).utc().format('YYYY-MM-DD HH:mm:ss') : '',
      //   date: this.currentDay.date,
      //   employee_uid: this.currentEmployee.uid,
      //   location: data.location,
      //   lunch_compensation: data.lunch && data.location === 'home' ? 1: 0,
      //   note: data.note ? data.note : '',
      //   overhead: data.overhead,
      //   wasted_hours: data.wasted_hours ? data.wasted_hours : 0,
      //   penalty: data.penalty,
      //   travel_compensation: data.travel && data.location === 'office' ? 1 : 0,
      //   type: data.type
      // });
      // await this.$store.dispatch('attendances/storeAttendance', fData)
      // if (this.currentDay.employee_uid) {
      //   fData.append('_method', 'PUT');
      // }
      // await this.$store.dispatch('attendances/updateDay', fData);
      // this.$bvModal.hide(this.daySettingsModalId);
      // this.$store.dispatch('attendances/getAttendances', this.queryParams);
    },
    handleSettingsModalClose() {
      this.$bvModal.hide(this.daySettingsModalId);
      this.currentDay = null;
      this.currentEmployee = null;
    },
    async handleDayClick(employee, attendance) {
      await this.$bvModal.show(this.daySettingsModalId);
      this.$store.commit('attendances/SET_SELECTED_DAY', { name: employee.name, uid: employee.uid, location_id: employee.location_id, data: attendance })
    },
    async handleDayTypeModalSubmit(type) {
      await this.$store.dispatch('attendances/setDayType', this.$convertToFormData({
        type,
        date: this.currentDay,
        _method: 'PATCH'
      }))
      await this.$store.dispatch('attendances/getAttendancesBeta', this.filterParams);
      this.$bvModal.hide(this.dayTypeModalId);
    },
    handleDayTypeModalClose() {
      this.currentDay = null;
      this.currentDayType = null;
    },
    handleHeaderDayClick(day) {
      const date = this.$moment(this.month, 'YYYY-MM-DD').format('YYYY-MM')
      this.currentDay = this.$moment(date +'-'+day, 'YYYY-MM-DD').format('YYYY-MM-DD')

      this.$bvModal.show(this.dayTypeModalId);
    },
    getTotalPenalties(items) {
      return items.reduce((acc, item) => {
        if (item.penalty) {
          acc += item.penalty;
        }

        return acc;
      }, 0);
    },
    getBackgroundClass(type) {
      switch (type) {
        case null:
          return 'calendar__days__item__content_weekend'
        case 'ordinary':
          return ''
        case 'danger':
          return 'calendar__days__item__content_red';
        case 'success':
          return 'calendar__days__item__content_green';
        case 'warning':
          return 'calendar__days__item__content_yellow';
        case 'day-off':
          return 'calendar__days__item__content_day-off';
        case 'danger-future':
          return 'calendar__days__item__content_danger-future';
        case 'normal-future':
          return 'calendar__days__item__content_normal-future';
        case 'day-off-future':
          return 'calendar__days__item__content_day-off-future';
        case 'maternity-leave':
          return 'calendar__days__item__content_day-maternity-leave';
      }
    },
    getVacationHours(total = 0, available = 0) {
      const spentHours = total - available;
      const hours = this.isVacationHoursSpentType ? spentHours : total - spentHours;

      return `${hours}/${total}`;
    },
    handleEditPeriodClick() {
      this.$bvModal.show(this.editPeriodModalId)
    },
    handleChangeVacationHoursType() {
      this.$store.commit(
          'attendances/SET_VACATION_HOURS_TYPE',
          this.isVacationTypeSwitchChecked ? VACATION_HOURS_TYPE.SPENT : VACATION_HOURS_TYPE.LEFT
      );
    },
    async handleEditPeriod(data) {
      this.loadModal = true
      await this.$store.dispatch(
          'attendances/updatePeriod',
          this.$convertToFormData({...data, '_method': 'POST'})
      ).then(async (res) => {
        this.$showSuccessToast(res?.message ?? 'The period was successfully changed')
        this.loadModal = false
        this.$bvModal.hide(this.editPeriodModalId)
        this.loadPage = true
        await this.fetchRequests().finally(() => {
          this.loadPage = false
        })
      })
    },
    async fetchRequests() {
      await this.$store.dispatch('attendances/getDayType')
      await this.$store.dispatch('attendances/getAttendancesBeta', this.filterParams).then(res => {
        if (res) {
          this.max_day = parseInt(this.$moment(this.filterParams?.date
              ? this.filterParams.date
              : this.months[1].value, 'YYYY-MM-DD').endOf('month').format('DD'))
        }
      })
    },
    async updateFilter(data) {
      this.loadPage = true
      await this.$store.commit('profile/SET_FILTER_PARAMS', {
        date: data.month,
        location_id: data.type,
        department_id: data.department,
        search_employee_name: this.search
      })
      await this.$store.dispatch('attendances/getAttendancesBeta', this.filterParams).then(res => {
        if (res) {
          this.setMonthData({
            month: data.month,
            max_day: parseInt(this.$moment(data.month).endOf('month').format('DD'))
          })
        }
      }).finally(() => {
        this.loadPage = false
      });
    },
    getMaternityStatus(employee) {
      if (employee.attendances && employee.attendances.length > 0) {
        return employee.attendances.some((el) => {
          return this.$moment(el.date, 'YYYY-MM-DD').isSame(this.$moment(), 'day')
              && el.attendance_type?.id === 5
        })
      }
      return false
    }
  },
  computed: {
    locations() {
      const data = this.$store.state.attendances.dictionary.locations.map(item => {
        return {
          text: item.title,
          value: item.id
        }
      })

      data.unshift({
        text: 'All',
        value: null
      })

      return data
    },
    months() {
      if (this.$store.state.attendances.months) {
        return this.$store.state.attendances.months.map(item => {
          return {
            text: this.$moment(item, 'YYYY-MM-DD').format('MMMM, YYYY'),
            value: item
          }
        })
      }

      return []
    },
    dayTypes() {
      return this.$store.state.attendances.dictionary.day_types.map(item => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    loading() {
      return this.$store.state.attendances.loading;
    },
    holidays() {
      return this.$store.state.attendances.holidays
    },
    filterParams () {
      return this.$store.state.profile.filterParams
    },
    employeesList() {
      return this.$store.getters['attendances/employeesItems'];
    },
    isVacationHoursSpentType() {
      return this.$store.getters['attendances/isVacationHoursSpentType'];
    },
    isVacationTypeSwitchChecked() {
      return !this.isVacationHoursSpentType;
    },
  },
  watch: {
    async 'search' (val) {
      if (val.length === 0 || val.length >= 3) {
        this.loadPage = true
        await this.$store.commit('profile/SET_FILTER_PARAMS', {
          date: this.month,
          location_id: this.type,
          department_id: this.department,
          search_employee_name: val
        })
        await this.$store.dispatch('attendances/getAttendancesBeta', this.filterParams).finally(() => {
          this.loadPage = false
        })
      }
    }
  },
  beforeDestroy() {
    this.$refs['calendar-body'].removeEventListener('scroll', this.setMyScrollLocation)
    this.$store.commit('attendances/SET_EMPLOYEES', [])
  }
}
</script>

<style lang="scss" scoped>
.timesheet {
  &__statuses {
    display: flex;
    right: 0px;
    bottom: 0px;
    position: fixed;
    background: #fff;
    padding: 5px 10px;
    border-radius: 10px 0px 0px 0px;
    box-shadow: 2px 0px 20px 5px rgba(#000000, .14);
    align-items: center;

    &__item {
      color: #2a3042;
      font-size: 12px;
      padding-left: 17px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        left: 0px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        background: #000;
      }
      &_light-blue {
        &::before {
          background: #c9d2fa;
        }
      }
      &_grey {
        &::before {
          background: #e7e7ee;
        }
      }
      &_dark-grey {
        &::before {
          background: #BDBDBD;
        }
      }
      &_pink {
        &::before {
          background: #fdc0e4;
        }
      }
      &_yellow {
        &::before {
          background: #fae281;
        }
      }
      &_orange {
        &::before {
          background: #fabd81;
        }
      }
      &_red {
        &::before {
          background: #fa9281;
        }
      }
      &_green {
        &::before {
          background: #b4eab9;
        }
      }
      &_late {
        &::before {
          background: #5fd1dd;
        }
      }
      &_maternity {
        &::before {
          background: #F422EC;
        }
      }
      &_holiday {
        &::before {
          background: #4567DC;
        }
      }
      &_day-off {
        &::before {
          background: #08DCD3;
        }
      }
      &_line {
        margin-left: 15px;
      }
      &_btn {
        button {
          background: transparent !important;
        }
      }
    }

    &__item + &__item {
      margin-left: 15px;
    }
    &.desktop-statuses {
      display: flex;
    }
    &.mobile-statuses {
      display: none;
    }
  }
}
.calendar {
  //overflow: hidden;
  background: #fff;
  overflow: scroll;
  border-radius: 4px;
  padding: 20px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    display: grid;
    grid-template-columns: 235px repeat(2, 55px) 1fr;
    border-bottom: 1px solid #E3E4E8;
    padding: 0 24px;
    position: sticky;
    top: -20px;
    background-color: inherit;
    z-index: 10;
    &__icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      transition: all .2s;
      &:hover {
        &::before {
          background-image: url("~@/assets/images/icons/ic-hourglass-left-spent.svg") !important;
        }
      }
      &::before {
        display: inline-block;
        content: '';
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 16px;
      }
      &.left-icon {
        &::before {
          background-image: url("~@/assets/images/icons/ic-hourglass-left.svg");
        }
      }
      &.spent-icon {
        &::before {
          background-image: url("~@/assets/images/icons/ic-hourglass-spent.svg");
        }
      }
    }
    &__item {
      display: flex;
      height: 39px;
      color: #fff;
      align-items: center;
      justify-content: left;
      background-color: inherit;
      position: relative;

      &:first-child {
        justify-content: flex-start;
        padding-right: 20px;
        input {
          border-radius: 4px;
          border: 1px solid transparent;
          height: 28px;
          padding: 0 10px;
          background: #EFF2F7;
          width: 100%;
          transition: all .2s;
          &::placeholder {
            opacity: .5;
          }
          &:active, &:focus {
            background: white;
            border: 1px solid #EFF2F7;
          }
        }
      }
      &:last-child {
        justify-content: flex-start;
      }
      .calendar__header__item-day {
        width: 27px;
        flex: none;
        cursor: pointer;
        font-size: 13px;
        line-height: 39px;
        text-align: center;
        padding-left: 1px;
        color: #2A3042;

        &.start-of-week {
          width: 29px;
          padding-left: 3px;
        }

        &:first-child.start-of-week {
          width: 27px;
          padding-left: 1px;
        }
      }
    }
  }
  &__body {
    display: grid;
    height: calc(100vh - 250px);
    grid-template-columns: 235px repeat(2, 55px) 1fr;
    padding: 20px 24px;
  }
  &__cell-text {
    height: 27px;
    line-height: 27px;
    font-size: 13px;
    color: #2a3042;
    text-align: left;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &_probation {
      color: #e49a51;
    }
    &_red {
      color: #fe0000;
    }
  }
  &__employees {
    &__item {
      text-align: left;
      position: relative;
      margin-left: -24px;
      padding-left: 34px;
      i {
        position: absolute;
        top: 6px;
        left: 12px;
        &.office-work-icon, &.remote-work-icon {
          &::before {
            content: '';
            width: 14px;
            height: 14px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 14px;
            display: inline-block;
            vertical-align: top;
          }
        }
        &.office-work-icon {
          &::before {
            background-image: url("~@/assets/images/icons/ic-office.svg");
          }
        }
        &.remote-work-icon {
          &::before {
            background-image: url("~@/assets/images/icons/ic-home.svg");
          }
        }
      }
    }

  }
  &__days {
    &__row {
      display: flex;
      .calendar__days__item:first-child {
        width: 27px;
        padding-left: 1px;
        &::before {
          display: none;
        }
      }
    }
    &__item {
      flex: none;
      width: 27px;
      height: 26px;
      padding-left: 1px;
      margin-top: 1px;

      &__content {
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #e7e7ee;
        position: relative;

        &_light-blue {
          background: #c9d2fa;
        }
        &_yellow {
          background: #fae281;
        }
        &_orange {
          background: #fabd81;
        }
        &_red {
          background: #fa9281;
        }
        &_purple {
          background: #FCC1E4;
        }
        &_green {
          background: #b4eab9;
        }
        &_late {
          background: #5fd1dd;
        }
        &_day-off {
          background: #08DCD3;
        }
        &_weekend {
          background: #BDBDBD;
        }
        &_danger-future {
          background: #fa9281;
          border: 1px solid #546EE5;
        }
        &_normal-future {
          background: #e7e7ee;
          border: 1px solid #546EE5;
        }
        &_day-off-future {
          background: #08DCD3;
          border: 1px solid #546EE5;
        }
        &_day-maternity-leave {
          background: #F422EC;
        }
      }

      &.start-of-week {
        width: 29px;
        padding-left: 3px;
        position: relative;

        &::before {
          content: '';
          top: -1px;
          left: 1px;
          width: 1px;
          flex: none;
          height: calc(100% + 1px);
          position: absolute;
          background: #546ee5;
        }
      }
    }
  }
}
.calendar__popup {
  z-index: 2;
  width: 179px;
  border-radius: 4px;
  padding: 9px 9px 11px 14px;
  pointer-events: none;
  position: fixed;
  background: #eeeef9;
  color: #3a4254;
  box-shadow: 2px 0px 20px 5px rgba(#000, .16);

  h4 {
    font-weight: bold;
    font-size: 13.5px;
    margin: 0px 0px 9px 0px;
  }

  h3 {
    font-size: 12px;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 12px;

    span {
      font-weight: bold;
    }
  }

  p + p {
    margin-top: 0px;
  }

  img {
    top: 8px;
    right: 9px;
    position: absolute;
  }
}
.vacation-days-control{
  .custom-control-label::before{
    background-color: #556ee6;
  }
  .custom-control-label::after{
    background-color: #FFFFFF;
  }
}

@media screen and (max-width: 991px) {
  .timesheet {
    &__statuses {
      &.desktop-statuses {
        display: none;
      }
      &.mobile-statuses {
        z-index: 11;
        display: flex;
      }
    }
  }
}
</style>
